import { Injectable } from '@angular/core';
import { PageSizeItem } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class Globals {
    constructor(private cookieService: CookieService) { }

    //env
    baseAPIFolderUrl: string = environment.apiUrl;
    baseAPIUrl: string = environment.apiUrl + '/api';
    baseUrl: string = environment.baseUrl;
    baseLoginUrl: string = environment.loginUrl;
    baseLogoutUrl: string = environment.logoutUrl;
    cookieDomainUrl: string = environment.cookieDomainUrl;
    env: string = environment.env;
    isProduction: boolean = environment.production;
    cookieLoginTokenName: string = 'web_login_token';
    cookieUserDataName: string = 'logged_in_user_info';

    //auth
    authData: any =
        typeof window !== 'undefined'
            ? this.cookieService.get(this.cookieUserDataName)
                ? JSON.parse(this.cookieService.get(this.cookieUserDataName))
                : null
            : null;

    userRole: number = this.authData ? this.authData.role_id : null;

    isLoggedIn: boolean = this.cookieService.get(this.cookieLoginTokenName) ? true : false;

    //common
    isLoading: boolean = false;
    alertTimer: number = 2000;
    currentLink: string = '';
    currentLinkArr: string[] = [];
    baseRoute: string = '';
    isAuthRoute: boolean = true;
    lastNotification: any = null;
    ddFilterLength: number = 7;
    regexName = /^[a-zA-Z0-9 '.-]{2,250}$/;
    encryptionKey: string = '9f8c95d47e4b31721a0e3a2d3f5c4b9dcd4f6e5a7f2e1c9b7e0a1b8d7f6c4a1c';
    impersonateUserId: string | null = null;
    impersonateUserName: string | null = null;

    // grid
    gridPageSize: number = 10;
    gridPageSizes: (PageSizeItem | number)[] = [
        10,
        20,
        50,
        {
            text: "All",
            value: "all",
        },
    ];
    gridPageButtonCount: number = 3;


    // interest list card view
    cardPageSize: number = 12;
    cardPageSizes: (PageSizeItem | number)[] = [
        12,
        24,
        60,
        {
            text: "All",
            value: "all",
        },
    ];
    cardPageButtonCount: number = 3;

    // alert list card view
    cardAlertPageSize: number = 4;
    cardAlertPageSizes: (PageSizeItem | number)[] = [
        4,
        8,
        16,
        {
            text: "All",
            value: "all",
        },
    ];

    // browserInfo = {
    //     'user_id': this.authData ? this.authData.id : null,
    //     'isMobile': this.deviceInfo.isMobile(),
    //     'isTablet': this.deviceInfo.isTablet(),
    //     'isDesktop': this.deviceInfo.isDesktop(),
    //     'deviceType': this.deviceInfo.getDeviceType(),
    //     'os': this.deviceInfo.getDeviceInfo().os,
    //     'osVersion': this.deviceInfo.getDeviceInfo().osVersion,
    //     'browser': this.deviceInfo.getDeviceInfo().browser,
    //     'browserVersion': this.deviceInfo.getDeviceInfo().browserVersion,
    //     'browserMajorVersion': this.deviceInfo.getDeviceInfo().browserMajorVersion,
    //     'screenResolution': this.deviceInfo.getDeviceInfo().screen_resolution,
    //     'cookies': this.deviceInfo.getDeviceInfo().cookies,
    //     'userAgent': this.deviceInfo.getDeviceInfo().userAgent,
    // };


    debounce(func: (...args: any[]) => void, wait: number) {
        let timeout: any;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }
}


