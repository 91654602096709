export const environment = {

  // // OE
  production: false,
  baseUrl: 'https://notification.stateside.devbyopeneyes.com/',
  loginUrl: "https://stateside.devbyopeneyes.com/login",
  logoutUrl: "https://stateside.devbyopeneyes.com/logout",
  apiUrl: 'https://api.stateside-notification.devbyopeneyes.com',
  cookieDomainUrl: 'devbyopeneyes.com',
  env: 'local',


  // // STATELINK
  // production: false,
  // baseUrl: 'https://app-dev.statelink.net/',
  // loginUrl: "https://app-dev.statelink.net/auth/login",
  // logoutUrl: "https://app-dev.statelink.net/auth/logout",
  // apiUrl: 'https://api-dev.statelink.net',
  // cookieDomainUrl: 'statelink.net',
  // env: 'local',

  // LOCAL
  // production: false,
  // baseUrl: 'https://stateside-app.test/',
  // loginUrl: "https://stateside-app.test/auth/login",
  // logoutUrl: "https://stateside-app.test/auth/logout",
  // apiUrl: 'https://api.stateside-notification.devbyopeneyes.com',
  // cookieDomainUrl: 'stateside-app.test',
  // env: 'local',
};